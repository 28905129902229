export default {
  attemptToReconnect: 'Attempting to reconnect...',
  betFor: 'for',
  bettingTimeLeft: 'Betting time left',
  coldField: 'Cold field',
  connected: 'Connected',
  connecting: 'Connecting',
  disconnected: 'Disconnected',
  eighthFinals: 'Eighth Finals',
  finalist: 'Finalist',
  finals: 'Finals',
  gameDeactivated: 'Game deactivated!',
  headToHead: 'Head to head',
  hotField: 'Hot field',
  knockoutStage: 'Knockout stage',
  latestRaces: 'Latest races',
  loading: 'Loading',
  outrights: 'Outrights',
  problemInCommunication: "There's a problem in communication with server!",
  problemWithGame: "There's a problem with game, please wait!",
  productName: 'Virtual Drag Races',
  qualifiesTo: 'Qualifies to',
  quarterFinalist: 'Quarter-finalist',
  quarterFinals: 'Quarter Finals',
  raceNo: 'Race no.',
  raceNow: 'now',
  racerOut: 'out',
  raceSchedule: 'Race schedule',
  raceTime: 'Race time',
  raceWinner: 'Race winner',
  reconnected: 'Reconnected',
  results: 'Results',
  round: 'Round',
  roundIn: 'in',
  roundNumber: 'Round number',
  roundStartsIn: 'Round {{roundNumber}} starts in',
  semiFinalist: 'Semi-finalist',
  semiFinals: 'Semi Finals',
  statistics: 'statistics',
  tournament: 'Tournament',
  tournamentNo: 'Tournament no.',
  tournamentOutrights: 'Tournament outrights',
  tournamentWinner: 'Tournament winner',
  upcoming: 'Upcoming',
  waitingForTheRace: 'Waiting for the race',
  winner: 'Winner',
  winnerShort: 'w',
};
